import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { DrillholeCountResponse, FilterObject } from 'state-domains/domain';

import {
    buildProjectOverviewCollarsCountUrl,
    buildProjectOverviewCollarsUrl,
} from '../../../network/urls';
import { convertToCamelWithSpecialParameters, sendRetrieveRequest } from '../../../utils';
import { OverviewCollar, OverviewCollarsResponse } from '../types';

export const loadProjectOverviewCollarsCount = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    coordinates: number[][], // rect starting w top right and going counter clockwise
): Observable<DrillholeCountResponse> => {
    const url = buildProjectOverviewCollarsCountUrl(
        projectId,
        activities,
        state,
        filters,
        coordinates,
    );
    return sendRetrieveRequest(url).pipe(switchMap(({ response }) => observableOf(response)));
};

export const loadProjectOverviewCollars = (
    projectId: string,
    activities: string[],
    state: string[],
    filters: FilterObject[],
    coordinates: number[][], // rect starting w top right and going counter clockwise
): Observable<OverviewCollar[]> => {
    const url = buildProjectOverviewCollarsUrl(projectId, activities, state, filters, coordinates);
    return sendRetrieveRequest(url).pipe(
        switchMap(({ response }: AjaxResponse<OverviewCollarsResponse>) => {
            const { data } = response;
            const result = convertToCamelWithSpecialParameters<OverviewCollar[]>(data, {
                id: 'uid',
            });
            return observableOf(result);
        }),
    );
};
